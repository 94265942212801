import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/folder/Login'
  },
  {
    path: '/folder/Login',
    component: () => import ('../views/login.vue'),
  },
  {
    path: '/folder/Pending',
    component: () => import ('../views/pending.vue')
  },
  {
    path: '/folder/Pick-up',
    component: () => import ('../views/pick-up.vue')
  },
  {
    path: '/folder/Document-pick-up',
    component: () => import ('../views/document-pick-up.vue')
  },
  {
    path: '/folder/Pick-up-photos',
    component: () => import ('../views/pick-up-photos.vue')
  },
  {
    path: '/folder/Pick-up-info',
    component: () => import ('../views/pick-up-info.vue')
  },
  {
    path: '/folder/Survey',
    component: () => import ('../views/survey.vue')
  },
  {
    path: '/folder/Receipt',
    component: () => import ('../views/receipt.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { logOut, settings, receipt, person, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Login',
        url: '/folder/Login',
        iosIcon: mailOutline,
        mdIcon: mailSharp,
      },
      {
        title: 'Pending Submissions',
        url: '/folder/Pending',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp
      },
      {
        title: 'Pick-up Location',
        url: '/folder/Pick-up',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
      {
        title: 'Document Pick-up',
        url: '/folder/Document-pick-up',
        iosIcon: archiveOutline,
        mdIcon: archiveSharp
      },
      {
        title: 'Pick-up Photos',
        url: '/folder/Pick-up-photos',
        iosIcon: trashOutline,
        mdIcon: trashSharp
      },
      {
        title: 'Pick-up Info',
        url: '/folder/Pick-up-info',
        iosIcon: warningOutline,
        mdIcon: warningSharp
      },
      {
        title: 'Survey',
        url: '/folder/Survey',
        iosIcon: warningOutline,
        mdIcon: warningSharp
      },
      {
        title: 'Receipt',
        url: '/folder/Receipt',
        iosIcon: warningOutline,
        mdIcon: warningSharp
      }
    ];

    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages, 
      archiveOutline,
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      receipt,
      person,
      settings,
      logOut,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
